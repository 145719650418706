<template>
  <div>
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7"></div>
        <div class="col-lg-6 col-5 text-right">
          <base-button
            size="sm"
            type="neutral"
            @click="$router.push({ name: 'AddBlogNetwork' })"
            ><i class="ni ni-fat-add"></i> Add New Blog</base-button
          >
        </div>
      </div>
    </base-header>

    <div class="container-fluid mt--6">
      <div>
        <card
          class="no-border-card"
          body-classes="px-0 pb-1"
          footer-classes="pb-2"
        >
          <template slot="header">
            <h3 class="mb-0">Danh sách dự án</h3>
          </template>
          <div>
            <div
              class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
            >
              <el-select
                class="select-primary pagination-select"
                v-model="listQuery.page_size"
                placeholder="Per page"
                @change="handleFilter"
              >
                <el-option
                  class="select-primary"
                  v-for="item in pagination.perPageOptions"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>

              <div>
                <base-input
                  v-model="listQuery.search"
                  prepend-icon="fas fa-search"
                  placeholder="Tìm kiếm..."
                  @keyup.enter.native="handleFilter"
                >
                </base-input>
              </div>
            </div>

            <el-table
              :data="tableData"
              row-key="id"
              header-row-class-name="thead-light"
              v-loading="listLoading"
              @sort-change="sortChange"
              @selection-change="selectionChange"
            >
              <el-table-column
                v-bind="{
                  label: 'Domain',
                  minWidth: 220,
                  sortable: true,
                }"
              >
                <template slot-scope="scope">
                  <router-link
                    :to="{
                      name: 'BlogNetworkDetail',
                      params: { id: scope.row.id },
                    }"
                    >{{ scope.row.domain }}</router-link
                  >
                </template>
              </el-table-column>

              <el-table-column
                v-bind="{
                  label: 'Trạng thái',
                  minWidth: 220,
                  sortable: false,
                }"
              >
                <template slot-scope="scope">
                  <badge
                    v-if="scope.row.state === 'online'"
                    rounded
                    type="success"
                    >{{ scope.row.state }}</badge
                  >
                  <badge v-else rounded type="danger">{{
                    scope.row.state
                  }}</badge>
                </template>
              </el-table-column>

              <el-table-column
                v-bind="{
                  label: 'Network',
                  minWidth: 160,
                  sortable: true,
                }"
              >
                <template slot-scope="scope">
                  {{ scope.row.network.title }}
                </template>
              </el-table-column>

              <el-table-column min-width="120px" align="right" label="">
                <div slot-scope="{ $index, row }" class="d-flex">
                  <!-- <font-awesome-icon icon="fa-solid fa-trash-alt text-danger" /> -->
                  <span
                    aria-hidden="true"
                    role="button"
                    @click="handleDelete($index, row)"
                    ><i
                      class="fa fa-trash-alt text-danger text-lg"
                      aria-hidden="true"
                    ></i
                  ></span>
                </div>
              </el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <!-- <div class="">
              <p class="card-category">Tổng cộng: {{ pagination.total }}</p>
            </div> -->
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :page-count="pagination.pageCount"
              :total="pagination.total"
              @input="getList"
            >
            </base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import { Table, TableColumn, Select, Option } from "element-ui";
import { BasePagination } from "@/components";
import swal from "sweetalert2";
import { formatToLocaleDatetime } from "@/utils";

export default {
  name: "ListBlogNetworks",
  components: {
    BasePagination,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  data() {
    return {
      tableData: [],
      pagination: {
        perPage: 100,
        currentPage: 1,
        perPageOptions: [10, 25, 50, 100],
        total: 0,
        pageCount: 1,
      },
      listQuery: {
        page: 1,
        page_size: 25,
        search: undefined,
        sortBy: "created_at",
      },
      listLoading: false,
      urls: [],
    };
  },
  filters: {
    formatToLocaleDatetime(dateTime) {
      return formatToLocaleDatetime(dateTime);
    },
  },
  created() {
    this.getList();
  },
  methods: {
    async getList(current_page) {
      // To prevent duplicate API call when input event emit from base pagination component
      if (current_page == this.listQuery.page) return;

      this.listLoading = true;
      this.listQuery.page = this.pagination.currentPage;
      const { page, pages_count, blogs } = await this.$store.dispatch(
        "blognetworks/fetchBlogNetworks",
        this.listQuery
      );

      this.tableData = blogs;
      this.pagination.total = pages_count;
      this.pagination.currentPage = page;
      this.pagination.perPage = this.listQuery.page_size;
      this.pagination.pageCount = pages_count;
      this.listQuery.page = page;
      this.listLoading = false;
    },
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows;
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    sortChange({ prop, order }) {
      if (prop) {
        this.tableData.sort((a, b) => {
          let aVal = a[prop];
          let bVal = b[prop];
          if (order === "ascending") {
            return aVal > bVal ? 1 : -1;
          }
          return bVal - aVal ? 1 : -1;
        });
      } else {
        this.tableData.sort((a, b) => {
          return a.id - b.id;
        });
      }
    },
    handleDelete(index, row) {
      swal
        .fire({
          title: "Xác nhận xóa blog service?",
          text: `Xóa blog service sẽ xóa tất cả dữ liệu từ khóa của blog service!`,
          icon: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn btn-success btn-fill",
            cancelButton: "btn btn-danger btn-fill",
          },
          confirmButtonText: "Đồng ý",
          cancelButtonText: "Hủy",
          buttonsStyling: false,
        })
        .then((result) => {
          if (result.value) {
            this.$preloaders.open();

            this.deleteRow(row).then((response) => {
              swal.fire({
                title: response.success ? "Thành công!" : "Lỗi!",
                text: response.message,
                icon: response.success ? "success" : "error",
                timer: 2000,
                timerProgressBar: true,
              });

              this.$preloaders.close();

              if (response.success) this.getList();
            });
          }
        });
    },
    async deleteRow(row) {
      return await this.$store.dispatch(
        "blognetworks/deleteBlogNetworkService",
        row.id
      );
    },
  },
};
</script>
<style>
.no-border-card .card-footer {
  border-top: 0;
}
</style>
